import { email, required } from "vuelidate/lib/validators";
import Message from "@/store/messages";
import api from "@/store/api";
import pagination from "@/store/pagination";

const crews = {
  namespaced: true,
  modules: {
    pg: pagination,
  },
  state: () => ({
    entities: ["de", "ch", "at"],
    create: {
      name: "",
      email: "",
      abbreviation: "",
      cities: [],
    },
    create_city: {
      city: "",
      country: "",
      country_code: "",
      place_id: "",
      position: {
        lat: "",
        lng: "",
      },
    },
    asp_selection_state: "inactive",
    current: null,
    list: [],
    dropdown: [],
  }),
  mutations: {
    create(state, value) {
      state.create = value;
    },
    add_city(state, val) {
      var city = {
        city: val.city,
        country: val.country,
        country_code: val.country_code,
        place_id: val.place_id,
        position: val.position,
      };
      state.create.cities.push(city);
    },
    remove_city(state, val) {
      state.create.cities.splice(val, 1);
    },
    current(state, value) {
      state.current = value;
    },
    asp_selection_state(state, value) {
      state.asp_selection_state = value;
    },
    add_current_city(state, val) {
      var city = {
        city: val.city,
        country: val.country,
        country_code: val.country_ode,
        place_id: val.place_id,
        position: { ...val.position },
      };
      state.current.cities.push(city);
    },
    remove_current_city(state, val) {
      state.current.cities.splice(val, 1);
    },
    list(state, value) {
      state.list = value;
    },
    dropdown(state, value) {
      var selectList = [];
      value.forEach((row, index) => {
        selectList[index] = {
          id: row["id"],
          title: row["name"],
          label: row["name"],
          cities: row["cities"].map((city) => ({ country: city.country })),
          subtitle: row["cities"][0]["country"],
          value: row["id"],
        };
      });
      state.dropdown = selectList;
    },
  },
  getters: {
    current(state) {
      return state.current;
    },
    list(state) {
      return state.list;
    },
    dropdown(state) {
      return state.dropdown;
    },
    entities(state) {
      return state.entities;
    },
    asp_selection_state(state) {
      return state.asp_selection_state;
    },
    getCrew: (state) => (id) => {
      return state.list.find((el) => el.id == id);
    },
    validations() {
      return {
        value: {
          name: { required },
          email: { required, email },
          abbreviation: { required },
          cities: { required },
        },
      };
    },
    findById: (state) => (id) => {
      return state.list.find((el) => el.id == id);
    },
  },
  actions: {
    async create({ dispatch }) {
      await dispatch({ type: "createReq" });
      await dispatch({ type: "list" });
    },
    async update({ dispatch }) {
      await dispatch({ type: "updateReq" });
      await dispatch({ type: "list" });
    },
    async delete({ dispatch }) {
      await dispatch({ type: "deleteReq" });
      await dispatch({ type: "list" });
    },
    createReq({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .post("/crews", state.create)
          .then((response) => {
            commit("create", { name: "", cities: [] });
            commit(
              "currentMsg",
              Message.getMessage("success", "crews.created"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    updateReq({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .put("/crews", state.current)
          .then((response) => {
            commit("current", null);
            commit(
              "currentMsg",
              Message.getMessage("success", "crews.updated"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    deleteReq({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .delete("/crews/" + state.current.id)
          .then(() => {
            commit("current", null);
            commit(
              "currentMsg",
              Message.getMessage("success", "crews.deleted"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    list({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/crews")
          .then((response) => {
            commit("list", response.data.payload);
            commit("dropdown", response.data.payload);
            commit("pg/pageSize", response.data.payload.length);
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    public({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/crews/public")
          .then((response) => {
            commit("list", response.data.payload);
            commit("dropdown", response.data.payload);
            commit("pg/pageSize", response.data.payload.length);
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    own({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/crews/own")
          .then((response) => {
            commit("current", response.data.payload);
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
export default crews;
